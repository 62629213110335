
.vue-form-wizard .wizard-icon-circle.tab_shape {
  background-color: #9A9A9A !important;
  color: white;
}
.vue-form-wizard .wizard-tab-content {
  display: flex; // to avoid horizontal scroll when animating
  .wizard-tab-container {
    display: block;
    animation: fadeIn 0.5s;
  }
}
